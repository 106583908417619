import {useCallback, useEffect, useState} from 'react'
import CardsList from '../../components/cards-list/CardsList'
import NoteModal from '../../components/note-modal/NoteModal'
import SideBar from '../../components/side-bar/SideBar'
import './Home.scss'
import firebase from 'firebase'

const Home = () => {
    const [favNotes, setFavNotes] = useState([])
    const [othersNotes, setOthersNotes] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [activeNote, setActiveNote] = useState({})
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(null)
    const [notes, setNotes] = useState([])
    const [empty, setEmpty] = useState(null)

    // const user = useContext(UserContext).user
    const userUid = firebase.auth().currentUser.uid

    const formatData = useCallback(
        (notesData) => {
            // format data here
            console.log('FORMAT DATA')

            // console.log('notes: ', notes)
            // console.log('user: ', user)
            if (!user || !user.hasOwnProperty('notes')) {
                return
            }
            let arr = []

            for (const key in notes) {
                const element = notes[key]
                if (user.notes.includes(key)) {
                    arr.push({...element})
                    arr = arr.sort((a, b) => b.timeStamp - a.timeStamp)
                    setFavNotes(arr.filter((note) => note.fav))
                    setOthersNotes(arr.filter((note) => !note.fav))
                }
            }
            setLoading(false)
        },
        [notes, user]
    )

    // get data here
    const getData = (userData) => {
        console.log('GET DATA')
        console.log('userData: ', userData)

        if (!userData.hasOwnProperty('notes') || (userData.notes && userData.notes.length < 1)) {
            setEmpty(true)
            setLoading(false)
            console.log('noooooo: ')
            setNotes([])

            return
        }
        firebase
            .database()
            .ref('/notes')
            .once('value')
            .then((snapshot) => {
                setNotes(snapshot.val())
            })
            .catch((err) => {
                console.log('error while getting data...: ', err)
                setLoading(false)
            })
    }

    const getUserData = useCallback(() => {
        // console.log('userUid inn: ', userUid)
        console.log('GET USER DATA')
        setLoading(true)
        setEmpty(false)

        firebase
            .database()
            .ref(`/users/${userUid}`)
            .once('value')
            .then((snapshot) => {
                // console.log('userData from DB: ', snapshot.val())
                const result = snapshot.val()
                console.log('RESULTTTT: ', result)

                setUser(snapshot.val())
                getData(snapshot.val())
            })
            .catch((err) => {
                console.log('error while getting userData...: ', err)
            })
    }, [userUid])

    const addNote = async (color) => {
        // setAllNotes([{color: color}, ...allNotes])
        console.log('ADD NOTE')

        const newNoteUid = await firebase.database().ref('/notes').push().key
        const timeStamp = Date.now()

        const note = {
            color: color,
            title: '',
            content: '',
            uid: newNoteUid,
            timeStamp: timeStamp,
            fav: 0
        }
        const newNotes = user.notes ? [...user.notes, newNoteUid] : [newNoteUid]

        await firebase
            .database()
            .ref(`/users/${user.uid}/notes`)
            .update(newNotes, (error) => {
                if (error) {
                    console.log('error while add to user notes: ', error)
                } else {
                    console.log('success added to user notes ')
                }
            })

        const updates = {}
        updates['/notes/' + newNoteUid] = note
        await firebase
            .database()
            .ref()
            .update(updates, async (error) => {
                if (error) {
                    console.log('error while posting: ', error)
                } else {
                    console.log('success res')
                    await getUserData()
                }
            })
    }

    const handleShowModal = (value, note) => {
        setActiveNote(note)
        if (!value) {
            getUserData()
        }
        setShowModal(value)
    }

    useEffect(() => {
        getUserData()
    }, [getUserData])

    useEffect(() => {
        formatData()
    }, [formatData, notes])

    return (
        <div className='home-container'>
            <SideBar addNote={addNote} />
            <div className='content'>
                {/* <h2 className='title'>Notes</h2> */}

                {loading && <h6>loading...</h6>}
                {empty && <h6>you havn't any note till now so you can start add a new one</h6>}
                {!loading && !empty && (
                    <>
                        <h3 className='sub-title'>Favorites</h3>
                        <CardsList data={favNotes} handleShowModal={handleShowModal} />

                        <h3 className='sub-title'>Others</h3>
                        <CardsList data={othersNotes} handleShowModal={handleShowModal} />
                    </>
                )}
            </div>

            {showModal && (
                <NoteModal showModal={showModal} handleShowModal={handleShowModal} data={activeNote} user={user} />
            )}
        </div>
    )
}

export default Home
