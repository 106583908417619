// import {CardColumns} from 'react-bootstrap'
import CardColumns from 'react-bootstrap/CardColumns'
import NoteCard from '../note-card/NoteCard'
// import 'bootstrap/dist/css/bootstrap.min.css'
import './CardsList.scss'
const CardsList = ({data, handleShowModal}) => {
    return (
        <CardColumns>
            {data.map((note, index) => (
                <NoteCard note={note} key={index} handleShowModal={handleShowModal} />
            ))}
        </CardColumns>
    )
}

export default CardsList
