import './App.scss'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Home from './pages/home/Home'
import Login from './pages/login/Login'
import firebase from 'firebase/app'
import {useEffect, useState} from 'react'
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics'
import {firebaseConfig} from './assets/constants'
// import {UserContext} from './contexts/UserContext'
import Policy from './pages/policy/Policy'

// Add the Firebase products that you want to use

function App() {
    const [loading, setLoading] = useState(true)
    // const [user, setUser] = useState(false)
    // const userContext = useContext(UserContext)
    const [auth, setAuth] = useState(false)

    // const getUserData = useCallback((uid) => {
    //     firebase
    //         .database()
    //         .ref(`/users/${uid}`)
    //         .once('value')
    //         .then((snapshot) => {
    //             console.log('userData from DB: ', snapshot.val())
    //             userContext.setUser(snapshot.val())
    //         })
    //         .catch((err) => {
    //             console.log('error while getting userData...: ', err)
    //         })
    // }, [])

    useEffect(() => {
        firebase.initializeApp(firebaseConfig)
        firebase.analytics()
        setLoading(false)
    }, [])

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // getUserData(user.uid)
                setAuth(true)
            } else {
                // userContext.setUser(null)
                setAuth(false)
            }
        })
    }, [])

    // console.log('user from context: ', userContext.user)

    if (loading) {
        return <div>loading...</div>
    }
    if (!auth) {
        return (
            <Router>
                <div className='App'>
                    <Route exact path='/' component={Login} />
                    <Route exact path='/policy' component={Policy} />
                </div>
            </Router>
        )
    }
    return (
        <Router>
            <div className='App'>
                <Switch>
                    <Route exact path='/' component={Home} />
                    {/* <Route exact path='/policy' component={Policy} /> */}
                </Switch>
            </div>
        </Router>
    )
}

export default App
