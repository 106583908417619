import styles from './SideBar.module.scss'
import {Image} from 'react-bootstrap'
import addIcon from '../../assets/icons/add.svg'
import logoutIcon from '../../assets/icons/logout.svg'

import {useState} from 'react'
import firebase from 'firebase'

// import 'animate.min.css'
const colors = ['yellow', 'orange', 'purple', 'blue', 'green']

const SideBar = ({addNote}) => {
    const [toggleAdd, setToggleAdd] = useState(false)

    const handleLogout = () => {
        firebase
            .auth()
            .signOut()
            .catch((err) => console.log('error while logout...'))
    }

    const handleToggle = (value) => {
        if (!value) {
            const dotElements = document.getElementsByClassName(styles['note-dot'])
            for (let i = 0; i < dotElements.length; i++) {
                const dot = dotElements[i]
                dot.classList.add('animate__zoomOutUp')
            }

            setTimeout(() => {
                setToggleAdd(value)
            }, 800)
        } else {
            setToggleAdd(value)
            return
        }
    }
    return (
        <div className={styles['side-bar-container']}>
            <h1 className={styles['brand']}>Nota</h1>
            <div className={styles['add-container']}>
                <div className={styles['icon-container']} onClick={() => handleToggle(!toggleAdd)}>
                    <Image alt='add-icon' src={addIcon} />
                </div>

                {toggleAdd && (
                    <div className={styles['note-dots-container']}>
                        {colors.map((color, index) => (
                            <div
                                id='dot-color'
                                key={index}
                                className={`${styles['note-dot']} ${styles[color]} animate__animated animate__zoomInDown `}
                                onClick={() => addNote(color)}
                            />
                        ))}
                    </div>
                )}
            </div>

            <div className={`${styles['icon-container']} ${styles['logout-icon-container']}`} onClick={handleLogout}>
                <Image alt='logout-icon' src={logoutIcon} />
            </div>
        </div>
    )
}

export default SideBar
