export const firebaseConfig = {
    apiKey: 'AIzaSyBBfNu8A7Aylusq6Kk1wYwkXB4jH4AUrYo',
    authDomain: 'nota.madkour17.com',
    databaseURL: 'https://notez-79354-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'notez-79354',
    storageBucket: 'notez-79354.appspot.com',
    messagingSenderId: '397709704298',
    appId: '1:397709704298:web:931df946e51f7a70399e13',
    measurementId: 'G-XLG0T1JVBK'
}
