import React, {useState} from 'react'
import PropTypes from 'prop-types'

export const UserContext = React.createContext({})

const UserContextProvider = ({children}) => {
    UserContextProvider.propTypes = {
        children: PropTypes.object
    }

    const [user, setUser] = useState(false)

    return (
        <UserContext.Provider
            value={{
                user: user,
                setUser: setUser
            }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContextProvider
