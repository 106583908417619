import styles from './NoteCard.module.scss'
import PropTypes from 'prop-types'
import {Image} from 'react-bootstrap'
// import editIcon from '../../assets/icons/edit.svg'
import favIcon from '../../assets/icons/fav.svg'
import moment from 'moment'

// const COLORS = {
//     yellow: 'yellow',
//     orange: 'orange',
//     purple: 'purple',
//     blue: 'blue',
//     green: 'green'
// }
const NoteCard = ({note, favorite, handleShowModal}) => {
    NoteCard.propTypes = {
        note: PropTypes.object,
        // color: PropTypes.oneOf(Object.keys(COLORS)),
        favorite: PropTypes.bool,
        handleShowModal: PropTypes.func
    }

    return (
        <div
            className={`${styles['note-card-container']} ${styles[note.color]} card`}
            onClick={() => handleShowModal(true, note)}>
            {note.fav ? (
                <div className={`${styles['fav-icon-container']} ${note.fav ? styles['active'] : ''}`}>
                    <Image alt='fav-icon' src={favIcon} />
                </div>
            ) : (
                ''
            )}
            <p className={styles['title']}>{note.title}</p>
            <p className={styles['content']}>{note.content}</p>
            <div className={styles['bottom-container']}>
                <p className={styles['date']}>{moment(note.timeStamp).calendar()}</p>
                {/* <div className={styles['edit-icon-container']} onClick={() => handleShowModal(true, note)}>
                    <Image alt='edit-icon' src={editIcon} />
                </div> */}
            </div>
        </div>
    )
}

export default NoteCard
