import {Button, Form, Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import styles from './Login.module.scss'
import fbIcon from '../../assets/icons/fb.svg'
import googleIcon from '../../assets/icons/google.svg'
import twitterIcon from '../../assets/icons/twitter.svg'
import {useState} from 'react'
import firebase from 'firebase'

const Login = () => {
    const [validated, setValidated] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(null)
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState(null)

    const addUserToDB = async (user) => {
        const userData = {
            name: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            uid: user.uid,
            avatar: user.photoURL,
            emailVerified: user.emailVerified,
            notes: []
        }

        await firebase
            .database()
            .ref('/users/')
            .update({[user.uid]: userData})
            .then((res) => console.log('add to db success: ', res))
            .catch((err) => console.log('error add to db: ', err))
    }

    const handleLogin = (event) => {
        setEmailError(null)
        setPasswordError(null)
        const form = event.currentTarget

        event.preventDefault()
        event.stopPropagation()

        // if (form.checkValidity() === false) {
        //     event.preventDefault()
        //     event.stopPropagation()
        // }

        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => setValidated(true))
            .catch((err) => {
                console.log('error in sign in...', err)
                if (err.code === 'auth/user-not-found') {
                    setEmailError('there is no user with this email')
                } else if (err.code === 'auth/wrong-password') {
                    setPasswordError('wrong password')
                }
            })
    }

    const handleGoogleLogin = async () => {
        const provider = new firebase.auth.GoogleAuthProvider()

        await firebase
            .auth()
            .signInWithPopup(provider)
            .then(async (result) => {
                /** @type {firebase.auth.OAuthCredential} */
                // const credential = result.credential

                // This gives you a Google Access Token. You can use it to access the Google API.
                // const token = credential.accessToken
                // The signed-in user info.
                const user = result.user
                if (result.additionalUserInfo.isNewUser) {
                    await addUserToDB(user)
                }
                console.log('google result: ', result)
                console.log('google user: ', user)
            })
            .catch((error) => {
                // // Handle Errors here.
                // var errorCode = error.code
                // var errorMessage = error.message
                // // The email of the user's account used.
                // var email = error.email
                // // The firebase.auth.AuthCredential type that was used.
                // var credential = error.credential
                // // ...

                console.log('error in sign in with google: ', error)
            })
    }

    const handleFbLogin = async () => {
        const provider = new firebase.auth.FacebookAuthProvider()

        await firebase
            .auth()
            .signInWithPopup(provider)
            .then(async (result) => {
                /** @type {firebase.auth.OAuthCredential} */
                // const credential = result.credential

                // This gives you a Google Access Token. You can use it to access the Google API.
                // const token = credential.accessToken
                // The signed-in user info.
                const user = result.user
                if (result.additionalUserInfo.isNewUser) {
                    await addUserToDB(user)
                }
                console.log('fb result: ', result)
                console.log('fb user: ', user)
            })
            .catch((error) => {
                // // Handle Errors here.
                // var errorCode = error.code
                // var errorMessage = error.message
                // // The email of the user's account used.
                // var email = error.email
                // // The firebase.auth.AuthCredential type that was used.
                // var credential = error.credential
                // // ...

                console.log('error in sign in with fb: ', error)
            })
    }

    const handleTwitterLogin = async () => {
        const provider = new firebase.auth.TwitterAuthProvider()

        await firebase
            .auth()
            .signInWithPopup(provider)
            .then(async (result) => {
                /** @type {firebase.auth.OAuthCredential} */
                // const credential = result.credential

                // This gives you a Google Access Token. You can use it to access the Google API.
                // const token = credential.accessToken
                // The signed-in user info.
                const user = result.user
                if (result.additionalUserInfo.isNewUser) {
                    await addUserToDB(user)
                }
                console.log('twitter result: ', result)
                console.log('twitter user: ', user)
            })
            .catch((error) => {
                // // Handle Errors here.
                // var errorCode = error.code
                // var errorMessage = error.message
                // // The email of the user's account used.
                // var email = error.email
                // // The firebase.auth.AuthCredential type that was used.
                // var credential = error.credential
                // // ...

                console.log('error in sign in with twitter: ', error)
            })
    }
    return (
        <div className={styles['login-container']}>
            {/* <h1>login page</h1> */}
            <div className={styles['form-container']}>
                <Form validated={validated} onSubmit={handleLogin}>
                    <h2>Welcome </h2>

                    <Form.Group controlId='formBasicEmail'>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type='email'
                            placeholder='Enter email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            isInvalid={emailError}
                            // isValid={!emailError}
                            // pattern='/^[^\s@]+@[^\s@]+$/'
                            pattern='^\S+@\S+$'
                            required
                        />
                        <Form.Control.Feedback type='valid'>correct</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>wrong email</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId='formBasicPassword'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type='password'
                            placeholder='Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            isInvalid={passwordError}
                            // isValid={!passwordError}
                            required
                        />
                        <Form.Control.Feedback type='valid'>correct</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Wrong Password</Form.Control.Feedback>

                        <Link to='/forgetpassword'>
                            <Form.Text className={`text-muted ${styles['forget-password']}`}>
                                Forget Password ?
                            </Form.Text>
                        </Link>
                    </Form.Group>

                    <Button variant='primary' type='submit'>
                        Login
                    </Button>

                    <h5>or using</h5>
                    <div className={styles['social-btns-container']}>
                        <Image alt='fb-icon' src={fbIcon} onClick={handleFbLogin} />
                        <Image alt='google-icon' src={googleIcon} onClick={handleGoogleLogin} />
                        <Image alt='twitter-icon' src={twitterIcon} onClick={handleTwitterLogin} />
                    </div>

                    {/* <Link to='/register'>
                        <h6>Signup using email</h6>
                    </Link> */}
                </Form>
            </div>

            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
                <path
                    fill='#a2d9ff'
                    fill-opacity='0.2'
                    d='M0,128L20,149.3C40,171,80,213,120,197.3C160,181,200,107,240,64C280,21,320,11,360,32C400,53,440,107,480,106.7C520,107,560,53,600,80C640,107,680,213,720,234.7C760,256,800,192,840,138.7C880,85,920,43,960,53.3C1000,64,1040,128,1080,176C1120,224,1160,256,1200,250.7C1240,245,1280,203,1320,181.3C1360,160,1400,160,1420,160L1440,160L1440,320L1420,320C1400,320,1360,320,1320,320C1280,320,1240,320,1200,320C1160,320,1120,320,1080,320C1040,320,1000,320,960,320C920,320,880,320,840,320C800,320,760,320,720,320C680,320,640,320,600,320C560,320,520,320,480,320C440,320,400,320,360,320C320,320,280,320,240,320C200,320,160,320,120,320C80,320,40,320,20,320L0,320Z'></path>
            </svg>
        </div>
    )
}

export default Login
