import {Form, Image, Modal, Spinner} from 'react-bootstrap'
import PropTypes from 'prop-types'
import styles from './NoteModal.module.scss'
import {useCallback, useEffect, useState} from 'react'
import favIcon from '../../assets/icons/fav.svg'
import deleteIcon from '../../assets/icons/delete.svg'
import colorsIcon from '../../assets/icons/colors.svg'

import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'

const NoteModal = ({showModal, handleShowModal, data, user}) => {
    NoteModal.propTypes = {
        showModal: PropTypes.bool,
        handleShowModal: PropTypes.func,
        note: PropTypes.object
    }
    const [note, setNote] = useState({...data})
    const [loading, setLoading] = useState(false)
    const [toggleColors, setToggleColors] = useState(false)
    // const [animation, setAnimation] = useState('animate__backInDown')

    const deleteNote = () => {
        const filteredNotes = user.notes.filter((noteUid) => noteUid !== note.uid)
        console.log('note.uid:  ', note.uid)
        console.log('user notes:  ', user.notes)
        console.log('filteredNotes:  ', [...filteredNotes])

        Swal.fire({
            title: 'Are you sure you want to delete this note?',
            showCancelButton: true,
            confirmButtonText: `Delete`
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const path = firebase.database().ref('notes').child(note.uid)

                path.remove()
                    .then(() => {
                        console.log('success delete from notes list ')
                        firebase
                            .database()
                            .ref(`/users/${user.uid}`)
                            .update({notes: [...filteredNotes]}, (error) => {
                                if (error) {
                                    console.log('error while delete from user notes: ', error)
                                } else {
                                    console.log('success delete from user notes ')
                                    handleShowModal(false)
                                    Swal.fire('Deleted!', '', 'success')
                                }
                            })
                    })
                    .catch((error) => console.log('error while delete: ', error))
            }
        })
    }

    const handleChange = (type, value) => {
        setNote({...note, timeStamp: Date.now(), [type]: value})
    }

    const updateNote = useCallback(async () => {
        setLoading(true)
        await firebase
            .database()
            .ref(`notes/${note.uid}`)
            .update(note)
            .then(() => {
                console.log('success updating ')
                setLoading(false)
            })
            .catch((error) => {
                console.log('error while updating: ', error)
                setLoading(false)
            })
    }, [note])

    useEffect(() => {
        updateNote()
    }, [note, updateNote])

    const onHide = () => {
        // setAnimation('animate__backOutUp')
        // handleShowModal(false)
        var x = document.getElementById('modal')
        // x.classList.add('animate__backOutUp')
        // x.classList.remove('animate__backInDown')
        x.classList.add('animate__bounceOutUp')

        setTimeout(() => {
            handleShowModal(false)
        }, 400)

        // x.c
        // handleShowModal(false)
    }

    // console.log('animation: ', animation)
    console.log('showModal: ', showModal)

    return (
        <Modal
            show={showModal}
            // onHide={() => handleShowModal(false)}
            id='modal'
            onHide={onHide}
            // oneEnter={() => setAnimation('animate__backInDown')}
            dialogClassName={`${styles['modal-dialog']} ${styles[note.color]} animate__animated animate__bounceInDown `}
            contentClassName={`${styles['modal-content']} ${styles[note.color]}`}>
            <Modal.Header
                className={styles['modal-header']}
                //  closeButton
            >
                <Modal.Title className={styles['modal-title']}>
                    <Form.Control
                        className={styles['title-text-area']}
                        // rows={1}
                        as='textarea'
                        placeholder='Title'
                        value={note.title}
                        onChange={(e) => handleChange('title', e.target.value)}
                    />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Control
                    as='textarea'
                    rows={7}
                    placeholder='content'
                    className={styles['text-area']}
                    value={note.content}
                    onChange={(e) => handleChange('content', e.target.value)}
                />
                <div className={styles['date-container']}>
                    {loading ? (
                        <Spinner animation='border' size='sm' className={styles['date']} />
                    ) : (
                        <p className={styles['date']}>{moment(note.timeStamp).calendar()}</p>
                    )}
                </div>

                <div className={styles['actions-here']}>
                    <div
                        className={`${styles['icon-container']} ${styles['fav-icon']} ${
                            note.fav ? styles['active'] : ''
                        }`}
                        onClick={() => handleChange('fav', !note.fav)}>
                        <Image alt='fav-icon' src={favIcon} />
                    </div>

                    <div className={`${styles['icon-container']}`} onClick={deleteNote}>
                        <Image alt='delete-icon' src={deleteIcon} />
                    </div>
                    <div
                        className={`${styles['icon-container']} ${styles['color-icon']}`}
                        onClick={() => setToggleColors(!toggleColors)}>
                        <Image alt='delete-icon' src={colorsIcon} />
                        {toggleColors && (
                            <div className={`${styles['colors-container']}`}>
                                <div
                                    className={`${styles['color-dot']} ${styles['yellow']} animate__animated animate__zoomInUp`}
                                    onClick={() => handleChange('color', 'yellow')}
                                />
                                <div
                                    className={`${styles['color-dot']} ${styles['orange']} animate__animated animate__zoomInDown`}
                                    onClick={() => handleChange('color', 'orange')}
                                />
                                <div
                                    className={`${styles['color-dot']} ${styles['purple']} animate__animated animate__zoomInUp`}
                                    onClick={() => handleChange('color', 'purple')}
                                />
                                <div
                                    className={`${styles['color-dot']} ${styles['blue']} animate__animated animate__zoomInDown`}
                                    onClick={() => handleChange('color', 'blue')}
                                />
                                <div
                                    className={`${styles['color-dot']} ${styles['green']} animate__animated animate__zoomInUp`}
                                    onClick={() => handleChange('color', 'green')}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default NoteModal
